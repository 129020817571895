<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("genotype-create.header") }}
      </h3>
      <GenotypeForm :genotype="genotype" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/genotype-create/submit-form";
import InitGenotypeCreateInteractor from "@/business/genotype-create/init-genotype-create";
import GenotypeCreateScreenController from "@/adapters/controllers/screen-genotype-create";
import GenotypeForm from "@/application/components/dna/GenotypeForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "genotype-create",
  components: { GenotypeForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initGenotypeCreate: null
      },
      data: null,
      isLoading: false,
      genotype: {
        value: "",
        snp: null,
        alleles: null
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenotypeCreateScreenController);

    //{ INTERACTORS
    this.interactors.initGenotypeCreate = this.$injector.get(
      InitGenotypeCreateInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initGenotypeCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.genotype);
    }
  }
};
</script>

<style lang="scss"></style>
